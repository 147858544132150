import Vue from "vue";
import Vuex from "vuex";
// constantes do sistema
import constants from "../constants/index";
// chamadas a API
import requests from "../requests/index";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    requests: requests,
    constants: constants,
    loading: false,
    msgs: [],
    tokenCaptcha: null,
    nome: "",
    cpf: "",
    mesReferencia:"",
    anoReferencia:"",
    dadosAluno: {},
    dadosSupermercado: {},
    retornoComDados: false,
    lstBeneficiarios: [],
    lstHistoricoVouchers: [],
    termoBuscaCpf: "",
    termoBuscaNome: "",
    termoBuscaCpfHist: "",
    flagMsgLoading: false,
    msgLoading: ""
  },
  mutations: {
    setMsgLoading(state, msgLoading) {
      state.msgLoading = msgLoading;
    },
    setFlagMsgLoading(state, flagMsgLoading) {
      state.flagMsgLoading = flagMsgLoading;
    },
    setLoading(state, loading) {
      state.loading = loading;
    },
    setTermoBuscaCpf(state, termo) {
      Vue.set(state, "termoBuscaCpf", termo);
    },
    setTermoBuscaCpfHist(state, termo) {
      Vue.set(state, "termoBuscaCpfHist", termo);
    },
    setTermoBuscaNome(state, termo) {
      Vue.set(state, "termoBuscaNome", termo);
    },
    registraRetornoComDados(state, retornoComDados) {
      Vue.set(state, "retornoComDados", retornoComDados);
    },

    registraTokenCaptcha(state, token) {
      Vue.set(state, "tokenCaptcha", token);
    },
    registraCpf(state, cpf) {
      state.cpf = cpf;
    },
    registraNome(state, nome) {
      state.nome = nome;
    },
    setMesReferencia(state, mesReferencia){
      state.mesReferencia = mesReferencia;
    },
    setAnoReferencia(state, anoReferencia){
      state.anoReferencia = anoReferencia;
    },
    limpaMsgs(state) {
      state.msgs = [];
    },
    limpaDados(state) {
      state.cpf = "";
      state.nome = "";
      state.dadosAluno = [];
      state.dadosSupermercado = [];
      state.retornoComDados = false;
    },
    setLstBeneficiarios(state, beneficiarios) {
      Vue.set(state, "lstBeneficiarios", beneficiarios);
    },
    setLstHistoricoVouchers(state, historicos) {
      Vue.set(state, "lstHistoricoVouchers", historicos);
    },
  },
  actions: {
    gravaTokenCaptcha({ commit }, token) {
      commit("registraTokenCaptcha", token);
    },
    gravaDadosForm({ commit }, { nome, cpf }) {
      commit("registraCpf", cpf);
      commit("registraNome", nome);
    },
    flushMsgs({ commit }) {
      commit("limpaMsgs");
    },
    flushDados({ commit }) {
      commit("limpaDados");
    },
  },
  getters: {
    http: (state) => state.requests,
    const: (state) => state.constants,
    loading: (state) => state.loading,
    tokenCaptcha: (state) => state.tokenCaptcha,
    cpf: (state) => state.cpf,
    nome: (state) => state.nome,
    mesReferencia:(state) => state.mesReferencia,
    anoReferencia:(state) => state.anoReferencia,
    retornoComDados: (state) => state.retornoComDados,
    lstBeneficiarios: (state) => state.lstBeneficiarios,
    lstHistoricoVouchers: (state) => state.lstHistoricoVouchers,
    termoBuscaCpf: (state) => state.termoBuscaCpf,
    termoBuscaNome: (state) => state.termoBuscaNome,
    termoBuscaCpfHist: (state) => state.termoBuscaCpfHist,
    flagMsgLoading: (state) => state.flagMsgLoading,
    msgLoading: (state) => state.msgLoading,
  },
});
