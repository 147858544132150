<template>
  <header class="navbar navbar-expand-lg navbar-light bg-light sticky-top">
    <!------------------ brand -------------------------->
    <a class="navbar-brand" :href="homeLink">
      <img
        :src="logoSmallURL"
        class="align-top logopbh"
        :title="'Prefeitura de Belo Horizonte - ' + sigla"
        alt="Logo Prefeitura de Belo Horizonte"
      />
      <span class="sigla">{{ sigla }}</span>
    </a>

    <!--------- botao menu hamburger --------------------->
    <button
      v-if="showMenu"
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <!----------------- menu itens ----------------------->
    <menu-itens
      v-if="showMenu"
      :itensMenu="itensMenu"
      :logoutCallback="logoutCallback"
      class="ml-auto"
    ></menu-itens>

    <!----------------- menu usuario --------------------->
    <menu-usuario
      v-if="showMenu && usuario"
      :nomeUsuario="usuario.name"
      :logoutCallback="logoutCallback"
    ></menu-usuario>
  </header>
</template>

<script>
export default {
  name: "Topo",
  components: {
    "menu-itens": require("./MenuItensSimples").default,
    "menu-usuario": require("./MenuUsuario").default,
  },
  props: {
    logoURL: {
      type: String,
      default: "",
    },
    logoSmallURL: {
      type: String,
      default: "",
    },
    sigla: {
      type: String,
      default: "",
    },
    showMenu: {
      type: Boolean,
      default: true,
    },
    itensMenu: {
      type: Array,
      default: () => [{}],
    },
    usuario: {
      type: Object,
      default: () => {},
    },
    logoutCallback: {
      type: Function,
      default: () => {},
    },
    homeLink: {
      type: String,
      default: "login",
    },
  },
  computed: {
    currentRoute() {
      return this.$route.path;
    },
    currentRouteAdmin() {
      return this.currentRoute == "/admin";
    },
    currentRouteRoot() {
      return this.currentRoute == "/";
    },
  },
  methods: {},
  mounted() {},
};
</script>

<style scoped>
.logopbh {
  max-height: 2.2em;
  width: auto;
  margin: 0;
  padding: 0;
}

.active {
  opacity: 1;
  visibility: visible;
  border-left-color: #4db6ac;
  margin-left: 2px;
  transition: all 0.25s;
}

.sigla {
  padding-top: 0.4em;
  display: inline-block;
  font-size: 25px;
  line-height: 25px;
  color: #646455;
  letter-spacing: 5px;
}

@media screen and (min-width: 320px) {
  .sigla {
    font-size: calc(20px + 6 * ((100vw - 320px) / 680));
  }
}
@media screen and (min-width: 1000px) {
  .sigla {
    margin-left: 0.4em;
    font-size: 25px;
  }
}
</style>
