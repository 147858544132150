require("./bootstrap");
/**
 * Arquivo JavaScript principal.
 * A partir daqui são incluídas todas as dependências.
 */
import "bootstrap/dist/css/bootstrap.css";

import Vue from "vue";
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue";
import Vuelidate from "vuelidate";
import VueProgressBar from "vue-progressbar";
import VuePapaParse from 'vue-papa-parse'
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUser,
  faSignOutAlt,
  faTrashAlt,
  faTrashRestoreAlt,
  faTimes,
  faSave,
  faEye,
  faEdit,
  faShoppingBasket,
  faMapMarked,
  faCalendar,
  faSearch,
  faCheck,
  faTicketAlt,
  faTasks,
  faQuestionCircle,
  faPhone,
  faCommentDots,
  faUsers,
  faPeopleCarry,
  faClock,
  faQuestion,
  faChildren,
  faChalkboardTeacher,
  faChild,
  faIdCard,
  faFile,
  faFileDownload,
  faCalendarCheck,
  faStore,
  faTicket,
  faSquareCheck,
  faExclamationTriangle,
  faFileImport,
  faFilePdf,
  faExclamationCircle,
  faFileExcel,
  faEraser,
  faFileCsv
} from "@fortawesome/free-solid-svg-icons";
import {
  faWhatsappSquare,
  faTelegram
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import Pbh from "@prodabel/piweb-lib-vue";
import keycloakvue from "@prodabel/vue-keycloak-adapter";

import router from "./routes";
import store from "./stores";
import helpers from "./helpers";
import filters from "./filters";
import Root from "./pages/paginaRaiz.vue";
import VueTheMask from "vue-the-mask";
import '@/assets/css/app.css';

Vue.use(VuePapaParse);
Vue.use(VueTheMask);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(Vuelidate);
library.add(
  faUser,
  faSignOutAlt,
  faTrashAlt,
  faTrashRestoreAlt,
  faTimes,
  faSave,
  faEye,
  faEdit,
  faShoppingBasket,
  faMapMarked,
  faCalendar,
  faSearch,
  faCheck,
  faTicketAlt,
  faTasks,
  faQuestion,
  faQuestionCircle,
  faPhone,
  faWhatsappSquare,
  faTelegram,
  faCommentDots,
  faUsers,
  faPeopleCarry,
  faClock,
  faChildren,
  faChalkboardTeacher,
  faChild,
  faIdCard,
  faFile,
  faFileDownload,
  faCalendarCheck,
  faStore,
  faTicket,
  faSquareCheck,
  faExclamationTriangle,
  faFileImport,
  faFilePdf,
  faExclamationCircle,
  faFileExcel,
  faEraser,
  faFileCsv
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(Pbh, {
  merge: {
    filters,
    helpers,
  },
});

helpers.getEnv().then((env) => {
  Vue.use(keycloakvue, {
    store,
    router,
    seconds_to_refresh: 60,
    permissions: {
      SUPERADMINISTRADOR: [
        "admin",
        "historico",
        "importacao-familias",
        "importacao-mercados",
        "log",
        "action-edit-nome-visualizado",
        "action-remove-familia",
        "action-edit-tipo-cesta",
        "estudades_escola_regional",
        "exportacao-dados-cesta-smed",
        "exportacao-dados-cesta-mercado",
        "cestas_por_tipo_escola_for_regional",
        "import_mercado_erros",
        "voucher_cancelado",
        "edicao_flag_retirado",
        "voucher_visualizado",
        "voucher_visualizado_familia"
      ],
      ADMINISTRADOR: ["admin", "historico"],
      ADMINISTRADOR_PARAMS: ["lista_params", "params", "inativar-familias"],
      any: ["home", "info", "403", "404", "5xx"],
    },
    verbose: env.VUE_APP_NODE_ENV === "local",
    redirect_url_logout: window.location.protocol + "//" + window.location.host,
    options: {
      redirectUri:
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname,
      // onLoad: "",
    },
    optionsConst: helpers.getOptsKeycloak(env),
    authErrorCallback: (e) => {
      console.error(e);
      router.push("403");
    },
  });

  Vue.use(VueProgressBar, {
    color: "#28a745",
    failedColor: "#874b4b",
    thickness: "5px",
  });

  Vue.prototype.$env = env;

  store.subscribe((mutation) => {
    // eslint-disable-line no-unused-vars
    if (mutation.type == "SET_KEYCLOAK") {
      router.setUserRoles(store.getters.userRoles, router);
    }
  });

  let app = new Vue({
    data() {
      return {
        carregando: false,
      };
    },
    store,
    router,
    render: (h) => h(Root),
  });
  if (window.Cypress) {
    window.vm = app;
    console.log("app has started");
    console.log("vm setted");
  }

  app.$mount("#app");
});
