<template>
  <div class="dropdown d-none d-lg-inline">
    <div class="btn-group dropstart">
      <a
        class="dropdown-toggle"
        type="button"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <font-awesome-icon class="mr-2" :icon="['fas', 'user']" />
      </a>
      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <!------------------ nome usuario --------------->
        <p class="dropdown-item disabled">{{ nomeUsuario }}</p>
        <div class="dropdown-divider"></div>
        <!------------------ logout --------------------->
        <a
          class="dropdown-item"
          @click="logoutCallback"
          style="cursor: pointer"
        >
          <font-awesome-icon class="mr-2" :icon="['fas', 'sign-out-alt']" />Sair
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: `MenuUsuario`,
  props: {
    nomeUsuario: { type: String, default: `` },
    logoutCallback: {
      type: Function,
      default: () => console.log(`Fazer logout!`),
    },
  },
};
</script>

<style></style>
