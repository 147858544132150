/**
 * @file Contém as constantes da aplicacao
 */
import sistema from "./sistema";
import relatorio from "./relatorio";

const constantes = Object.freeze({
    LOCALE: 'pt-BR',
    BASE_URL: window.location.protocol + '//' + window.location.host,
    PBH_URL: 'https://prefeitura.pbh.gov.br/',
    sistema,
    relatorio
});

export default constantes;
