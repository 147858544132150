//telas externas
let home = () => import(/* webpackChunkName: "cidadao" */ "../pages/home.vue");
let info = () => import(/* webpackChunkName: "cidadao" */ "../pages/info.vue");
let admin = () => import(/* webpackChunkName: "admin" */ "../pages/admin.vue");
let historico = () =>
  import(/* webpackChunkName: "admin" */ "../pages/hist_vouchers.vue");
let edicaoRetirado = () =>
  import(/* webpackChunkName: "admin" */ "../pages/edit_flag_retirado.vue");
let params = () =>
  import(/* webpackChunkName: "admin" */ "../pages/params.vue");
let importacaoFam = () =>
  import(/* webpackChunkName: "admin" */ "../pages/importFamilias.vue");
let importacaoMercado = () =>
  import(/* webpackChunkName: "admin" */ "../pages/importMercados.vue");
// let importacaoMer = () =>
//   import(/* webpackChunkName: "admin" */ "../pages/importFamilias.vue");
let log = () =>
  import(/* webpackChunkName: "admin" */ "../pages/lista_log.vue");
let lista_params = () =>
  import(/* webpackChunkName: "admin" */ "../pages/lista_params.vue");
let inativarFamilias = () =>
  import(/* webpackChunkName: "admin" */ "../pages/inativarFamilias.vue");

//relatorios
let estudades_escola_regional = () =>
  import(/* webpackChunkName: "relatorios" */ "../pages/relatorios/estudantesPorEscolaRegional.vue");
let cestas_por_tipo_escola_for_regional = () =>
  import(/* webpackChunkName: "relatorios" */ "../pages/relatorios/cestasPorTipoEscolaForRegional.vue");
let import_mercado_erros = () =>
  import(/* webpackChunkName: "relatorios" */ "../pages/relatorios/importMercadoErros.vue");
let voucher_cancelado = () =>
  import(/* webpackChunkName: "relatorios" */ "../pages/relatorios/voucherCancelado.vue");
let voucher_visualizado = () =>
  import(/* webpackChunkName: "relatorios" */ "../pages/relatorios/voucherVisualizado.vue");
let voucher_visualizado_familia = () =>
  import(/* webpackChunkName: "relatorios" */ "../pages/relatorios/voucherVisualizadoFamilia.vue");
//exportação
let exportacaoDadosCestaSMED = () =>
  import(/* webpackChunkName: "exportacao" */ "../pages/exportacao/dadosCestaSMED.vue");
let exportacaoDadosCestaMercado = () =>
  import(/* webpackChunkName: "exportacao" */ "../pages/exportacao/dadosCestaMercado.vue");

//Telas de erro
let pagina5xx = () =>
  import(/* webpackChunkName: "errors" */ "../pages/erros/5xx.vue");
let pagina403 = () =>
  import(/* webpackChunkName: "errors" */ "../pages/erros/403.vue");
let pagina404 = () =>
  import(/* webpackChunkName: "errors" */ "../pages/erros/404.vue");

const routes = [
  {
    name: "home",
    path: "/",
    component: home,
  },
  {
    name: "info",
    path: "/info",
    component: info,
  },
  {
    name: "admin",
    path: "/admin",
    component: admin,
  },
  {
    name: "historico",
    path: "/admin/historico",
    component: historico,
  },
  {
    name: "edicao_flag_retirado",
    path: "/admin/editar-retirado",
    component: edicaoRetirado,
  },
  {
    name: "params",
    path: "/admin/params",
    component: params,
  },
  {
    name: "lista_params",
    path: "/admin/lista-params",
    component: lista_params,
  },
  {
    name: "inativar-familias",
    path: "/admin/inativar-familias",
    component: inativarFamilias,
  },
  {
    name: "estudades_escola_regional",
    path: "/relatorio/estudades-por-escola-regional",
    component: estudades_escola_regional,
  },
  {
    name: "cestas_por_tipo_escola_for_regional",
    path: "/relatorio/cestas_por_tipo_escola_for_regional",
    component: cestas_por_tipo_escola_for_regional,
  },
  {
    name: "import_mercado_erros",
    path: "/relatorio/import_mercado_erros",
    component: import_mercado_erros,
  },
  {
    name: "voucher_cancelado",
    path: "/relatorio/voucher_cancelado",
    component: voucher_cancelado,
  },
  {
    name: "voucher_visualizado",
    path: "/relatorio/voucher_visualizado",
    component: voucher_visualizado,
  },
  {
    name: "voucher_visualizado_familia",
    path: "/relatorio/voucher_visualizado_familia",
    component: voucher_visualizado_familia,
  },
  {
    name: "importacao-familias",
    path: "/admin/importacao-familias",
    component: importacaoFam,
  },
  {
    name: "importacao-mercados",
    path: "/admin/importacao-mercados",
    component: importacaoMercado,
  },
  {
    name: "log",
    path: "/admin/importacao-familias/log",
    component: log,
  },
  {
    name: "exportacao-dados-cesta-smed",
    path: "/exportacao/dados-cesta-smed",
    component: exportacaoDadosCestaSMED,
  },
  {
    name: "exportacao-dados-cesta-mercado",
    path: "/exportacao/dados-cesta-mercado",
    component: exportacaoDadosCestaMercado,
  },
  {
    name: "5xx",
    path: "/5xx",
    component: pagina5xx,
  },
  {
    name: "403",
    path: "/403",
    component: pagina403,
  },
  //   {
  //     name: "404",
  //     path: "/404",
  //     component: pagina404,
  //   },
  //tela "não encontrada"
  {
    name: "404",
    path: "*",
    component: pagina404,
  },
];

export default routes;
