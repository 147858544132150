import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import api from "../requests";
import store from "../stores";
import constants from "../constants";

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: "history",
  linkExactActiveClass: "active",
});

router.setUserRoles = (userRoles, router) => {
  router.$userRoles = userRoles;

};

const checkManutencaoByRoles = async (userRoles, next = null) => {
  let env = Vue.prototype.$env;
  let { data } = await api.get(env.VUE_APP_BACKEND_URL + "/manutencao");
  if (
    data.dados.flag_manutencao === "1" &&
    !userRoles.includes("SUPERADMINISTRADOR") &&
    !userRoles.includes("ADMINISTRADOR") &&
    !userRoles.includes("ADMINISTRADOR_PARAMS")
  ) {
    const url = env.MANUTENCAO_REDIRECT_URL || constants.PBH_URL
    window.location.href = url;
  } else {
    next();
  }
};

router.beforeResolve(async (to, from, next) => {
  if (to.name != "5xx" && to.name != "403" && to.name != "404") {
    let roles = await (router.$userRoles ? router.$userRoles : []);
    try {
      store.commit("setLoading", true);
      await checkManutencaoByRoles(roles, next);
      store.commit("setLoading", false);
    } catch (error) {
      store.commit("setLoading", false);
      console.error(error);
      router.push("5xx");
    }
  } else if (router.$userRoles.length == 1 && router.$userRoles.includes("ADMINISTRADOR_PARAMS")) {
    router.push("admin/lista-params");
  } else {
    next();
  }
});

export default router;
