import constantes from "../constants";
import moment from 'moment';

async function getEnv() {
  try {
    let data = await fetch(`${constantes.BASE_URL}/env.json`, {
      mode: "no-cors",
    });
    let result = data.json();
    return result;
  } catch (err) {
    console.log(err);
  }
}

function getOptsKeycloak(environment) {
  if (!environment.ACESSO_URL || !environment.ACESSO_CLIENT_ID) return null;
  let opts = {
    url: environment.ACESSO_URL,
    realm: "PBH",
    clientId: environment.ACESSO_CLIENT_ID,
  };
  return opts;
}

const formatDateHourToDateTimeBr = (value) => {
  if (value) {
    return moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm:ss');
  }
  return null;
}

const formatDateHourToDate = (value) => {
  if (value) {
    return moment(value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
  }
  return null;
}

const downloadFile = (blob, fileName) => {
  const url = window.URL.createObjectURL(new Blob([blob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

const showToast = (el, titulo, mensagem, variant) => {
  el.$bvToast.toast(mensagem, {
    title: titulo,
    variant: variant,
    solid: true
  });
}

const getExtensaoArquivo = (filename) => {
  return '.' + filename.split('.').pop();
}

const validaExtensaoArquivo = (filename, extPermitidas) => {
  const extFile = getExtensaoArquivo(filename);
  return extPermitidas.find(function (ext) { return extFile.toLowerCase() == ext.toLowerCase(); });
}

const convertCSVToArray = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsText(file);
  reader.onload = () => resolve(reader.result.split('\n').map(function (line) { return line.split(',') }));
  reader.onerror = error => reject(error);
})

const getDescricaoTipoCesta = (tipo) => {
  switch (tipo) {
    case "1":
      return "1 - Cesta Pequena (Janeiro)";
    case "2":
      return "2 - Cesta Grande (Janeiro)";
    case "3":
      return "3 - Cesta Pequena (Julho)";
    case "4":
      return "4 - Cesta Grande (Julho)";
    default:
      return "n/a";
  }
}

export { getEnv, getOptsKeycloak, formatDateHourToDate, formatDateHourToDateTimeBr, downloadFile, showToast, validaExtensaoArquivo, convertCSVToArray, getDescricaoTipoCesta };
export default { getEnv, getOptsKeycloak, formatDateHourToDate, formatDateHourToDateTimeBr, downloadFile, showToast, validaExtensaoArquivo, convertCSVToArray, getDescricaoTipoCesta };
