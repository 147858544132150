import axios from "axios";
import router from "../routes";
import helpers from "../helpers";
import { version } from "../../package.json";

var api = axios.create({
  responseType: "json",
  formAutenticacao: "modal",
  headers: {
    "X-Client-Version": version,
    "Access-Control-Allow-Headers": "X-Client-Version"
  }
});

helpers.getEnv().then((env) => {
  api.defaults.baseURL = env.VUE_APP_BACKEND_URL;
});

/**
 * obj loading
 * garante que havera apenas 1 loader
 * (to rule them all)
 */
const loading = {
  list: [],
  start(item = 0) {
    // adiciona id da requisicao feita
    this.list.push(item);
    // inicia o loader, na primeira requisicao realizada
    if (this.list.length === 1) router.app.carregando = true;
  },
  finish(item = 0) {
    // busca o id da requisicao finalizada
    let index = this.list.indexOf(item);
    if (index !== -1) {
      // retira a requisicao finalizada encontrada
      this.list.splice(index, 1);
    }
    // so remove o loader se nao ha mais nenhuma requisicao na fila
    if (this.list.length === 0) router.app.carregando = false;
  },
};

// interceptor da request para inserir
// o id da requisicao na fila do loader
api.interceptors.request.use((config) => {
  config.request_id = Math.random();
  loading.start(config.request_id);
  return config;
});

// interceptor da response para tratar
// 1. o loader
// 2. os diversos tipos de respostas do backend
api.interceptors.response.use(
  (response) => {
    loading.finish(response.config.request_id);
    return response;
  },
  (error) => {
    if (error.response) {
      loading.finish(error.response.config.request_id);
      let status = Number.parseInt(error.response.status);
      let objVue = router.app;

      // tratando mensagem de erros quando esperamos um blob
      if (
        error.request.responseType === "blob" &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf("json") != -1
      ) {
        return new Promise((resolve, reject) => {
          let reader = new FileReader();
          reader.onload = () => {
            error.response.data = JSON.parse(reader.result);
            resolve(Promise.reject(error));
          };

          reader.onerror = () => {
            reject(error);
          };

          reader.readAsText(error.response.data);
        });
      }
      // erros de servidor
      // version mismatch
      if (status == 488) {
        const h = objVue.$createElement
        const content = h('div', {
          domProps: {
            innerHTML: `Você está utilizando uma versão desatualizada do sistema (${version}). <br>
        Favor carregar a versão mais recente (pressione CTRL+F5 ou clique no botão "Recarregar"). <br>` }
        })

        objVue.$bvModal.msgBoxConfirm([content], {
          title: `Alerta de Atualização da versão`,
          size: 'md',
          buttonSize: 'md',
          okVariant: 'warning',
          okTitle: "Recarregar",
          cancelTitle: "Cancelar",
          headerClass: 'bg-warning',
        })
          .then(value => {
            if (value === true) window.location.reload()
          })
          .catch(err => {
            // An error occurred
            console.log(err)
          })
      }

      if (status > 499) {
        objVue.$helper.printMsg(objVue, error, {
          dialog: {
            variant: "danger",
            title: `Erro de servidor - ${status}`,
          },
        });
      }
    }

    return Promise.reject(error);
  }
);

export { api, loading };

export default api;
