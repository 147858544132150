/**
 * @file Contém as constantes do relatorio
 */
const relatorio = {
    TIPO:{
        PDF: 'pdf', 
        EXCEL: 'excel'
    }
}
export default relatorio;
