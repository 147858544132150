<template>
  <div class="d-flex flex-column" id="app">
    <vue-progress-bar></vue-progress-bar>
    <topo class="px-5 py-2" logoURL="/imgs/brasao-pbh.png" logoSmallURL="/imgs/brasao-small.png" sigla="Cesta Estudantes"
      homeLink="/" :showMenu="$store.getters.isAuthenticated" :usuario="usuario" :itensMenu="itens_menu"
      :logout-callback="logout"></topo>
    <b-overlay :show="$store.getters.loading" rounded="sm">
      <template #overlay>
        <div class="text-center">
          <b-spinner>
            <b-icon icon="star-fill"></b-icon>
          </b-spinner>
          <p v-if="$store.getters.flagMsgLoading">
            {{ $store.getters.msgLoading }}
          </p>
        </div>
      </template>
      <div class="container" id="content">
        <router-view></router-view>
      </div>
    </b-overlay>
    <rodape class="p-2" logoURL="/imgs/brasao-pbh.png" :versao="versao"></rodape>
  </div>
</template>

<script>
import helpers from "../helpers";
export default {
  components: {
    topo: require("../components/layout/TopoCustom.vue").default,
  },
  computed: {
    sigla() {
      return this.$env.VUE_APP_SIGLA || "";
    },
    versao() {
      return this.$store.getters.const.sistema.versao || "";
    },
    usuario() {
      return { name: this.$store.getters.userInfo.name };
    },
    itens_menu() {
      let itens = [{ titulo: "Home", to: { name: "home", path: "/" } }];
      if (this.can("admin")) {
        itens.push({
          titulo: "Admin",
          itens: [
            {
              titulo: "Gerenciar beneficiário",
              to: {
                name: "admin",
                path: "/admin",
              },
            },
            {
              titulo: "Gerenciar dados de retirada",
              to: {
                name: "edicao_flag_retirado",
                path: "/admin/editar-retirado",
              },
            },
            {
              titulo: "Históricos",
              to: { name: "historico", path: "/admin/historico" },
            }
          ],
        });
      }
      if (this.can("importacao-familias")) {
        itens.push({
          titulo: "Importação",
          itens: [
            {
              titulo: "Importar famílias",
              to: {
                name: "importacao-familias",
                path: "/admin/importacao-familias",
              },
            },
            {
              titulo: "Importação do retorno do mercado",
              to: {
                name: "importacao-mercados",
                path: "/admin/importacao-mercados",
              },
            },
          ],
        });
        itens.push({
          titulo: "Exportação",
          itens: [
            {
              titulo: "Exportar Dados SMED",
              to: {
                name: "exportacao-dados-cesta-smed",
                path: "/exportacao/dados-cesta-smed",
              },
            },
            {
              titulo: "Exportar Dados Mercado",
              to: {
                name: "exportacao-dados-cesta-mercado",
                path: "/exportacao/dados-cesta-mercado",
              },
            },
          ],
        });
      }
      if (this.can("params")) {
        itens.push({
          titulo: "Parâmetros",
          itens: [
            {
              titulo: "Parâmetros",
              to: { name: "list_params", path: "/admin/lista-params" },
            },
            {
              titulo: "Inativar famílias",
              to: {
                name: "inativar_familias",
                path: "/admin/inativar-familias",
              },
            },
          ],
        });
      }
      if (this.can("estudades_escola_regional")) {
        itens.push({
          titulo: "Relatórios",
          itens: [
            {
              titulo: "Estudantes por Escola x Regional",
              to: {
                name: "estudades_escola_regional",
                path: "/relatorio/estudades-por-escola-regional",
              },
            },
            {
              titulo: "Cestas por Tipo x Escola x Fornecedor x Regional",
              to: {
                name: "cestas_por_tipo_escola_for_regional",
                path: "/relatorio/cestas_por_tipo_escola_for_regional",
              },
            },
            {
              titulo: "Inconsistências Importação Arquivo Mercado",
              to: {
                name: "import_mercado_erros",
                path: "/relatorio/import_mercado_erros",
              },
            },
            {
              titulo: "Beneficiários x Voucher Cancelado",
              to: {
                name: "voucher_cancelado",
                path: "/relatorio/voucher_cancelado",
              },
            },
            {
              titulo: "Vouchers Visualizados",
              to: {
                name: "voucher_visualizado",
                path: "/relatorio/voucher_visualizado",
              },
            },
            {
              titulo: "Vouchers Visualizados/Não Visualizados - Dados",
              to: {
                name: "voucher_visualizado_familia",
                path: "/relatorio/voucher_visualizado_familia",
              },
            },
          ],
        });
      }
      return itens;
    },
  },
  methods: {
    logout() {
      return this.$store.dispatch("LOGOUT_REQUEST");
    },
    setGoogleAnalytics(){
        helpers.getEnv().then((response) => {
        const googleAnalyticsId = response.VUE_APP_GOOGLE_ANALYTICS_ID;

        if(googleAnalyticsId){
          const scriptGTM1 = window.document.createElement('script');
          scriptGTM1.setAttribute('async', '');
          scriptGTM1.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`);
          window.document.head.appendChild(scriptGTM1);

          const scriptGTM2 = window.document.createElement('script');
          scriptGTM2.innerText = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${googleAnalyticsId}');`
          window.document.head.appendChild(scriptGTM2);
        }
      });
    },
  },
  mounted() {
    this.$Progress.finish();
    this.setGoogleAnalytics();
  },
  created() {
    this.$Progress.start();

    this.$router.beforeEach((to, from, next) => {
      this.$Progress.start();
      this.$store.commit("setLoading", true);
      next();
    });

    this.$router.afterEach(() => {
      this.$store.commit("setLoading", false);
      this.$Progress.finish();
    });
  },
};
</script>
<style>
#content.container {
  min-height: 82vh;
}
</style>
