<template>
  <span class="container">
    <!-------------- menu hamburger --------------------->
    <div class="collapse navbar-collapse" :id="idNavbarSupportedContent">
      <ul class="navbar-nav ml-auto mr-3">
        <!--------- itens (1o. nivel) --------------------->
        <li v-for="item in itensMenu" :key="item.titulo">
          <router-link
            v-if="item.to"
            class="nav-link dropdown-item"
            :to="item.to.path"
            >{{ item.titulo }}</router-link
          >

          <!--------- itens (2o. nivel) --------------------->
          <div v-else class="nav-link dropdown-center align-middle">
            <div>
              <span
                class="dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                {{ item.titulo }}
              </span>
              <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <li v-for="subitem in item.itens" :key="subitem.titulo">
                  <router-link
                    v-if="subitem.to"
                    class="dropdown-item"
                    :to="subitem.to.path"
                    >{{ subitem.titulo }}</router-link
                  >
                </li>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div class="dropdown-divider"></div>
      <!------------------ logout --------------------->
      <a
        class="dropdown d-inline d-lg-none"
        @click="logoutCallback"
        style="cursor: pointer"
      >
        <font-awesome-icon class="mr-2" :icon="['fas', 'sign-out-alt']" />Sair
      </a>
    </div>
  </span>
</template>

<script>
export default {
  name: `MenuItensSimples`,
  props: {
    itensMenu: { type: Array, default: () => [{}] },
    logoutCallback: {
      type: Function,
      default: () => console.log(`Fazer logout!`),
    },
    idNavbarSupportedContent: {
      type: String,
      default: "navbarSupportedContent",
    },
  },
};
</script>

<style scoped>
.dropdown-menu {
  top: 70%;
  min-width: 15em;
}
</style>
