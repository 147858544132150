/**
 * @file Contém as constantes do sistema
 */
const pck = require("../../package.json");

const sistema = {
  sigla: "SISTEMA",
  descricao: "Sistema Básico",
  versao: pck.version,
};
export default sistema;
